import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Headshot from "../components/Headshot";
import { Feature } from "../components/Features";

import logo from "../img/signature-solid-navy.svg";
import sprout from "../img/decorative/sprout-heart.svg";
import gptw from "../img/logos/awards/gptw-2020.png";
import twp from "../img/logos/awards/twp-2020.png";

import { faHome, faGift, faSuitcase } from "@fortawesome/pro-regular-svg-icons";

export const AboutPageTemplate = ({
  content,
  contentComponent,
  subtitle,
  photoPrimary,
  photoSecondary,
  photoTertiary,
  // TODO: Don't hardcode this!!
  amanda,
  dustin,
  katie,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <section className="container mt-0 md:mt-8 md:space-y-4 lg:space-y-5">
        <div className="columns pb-4">
          <div className="w-full md:w-10/12 xl:w-full">
            <div className="p-8 md:p-6 lg:p-12 xl:px-24 xl:py-12 lg:py-8 bg-accent-p2 rounded-lg lg:rounded-xl sm:flex items-center justify-between sm:space-x-6 space-y-6 sm:space-y-0 text-center sm:text-left">
              <h3 className="flex-shrink font-heading text-2xl md:text-3xl lg:text-4xl">
                We're Hiring! Join&nbsp;our&nbsp;Team
              </h3>

              <AnchorLink
                to="https://recruiting.paylocity.com/recruiting/jobs/All/32ce305a-6464-4d04-8a69-f5e0c909e625/ScriptDrop-Inc"
                className="btn btn-accent btn-avenir-semibold w-full sm:w-auto px-6 lg:px-12 text-sm md:text-md lg:text-lg"
              >
                See&nbsp;Open&nbsp;Positions&nbsp;→
              </AnchorLink>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="w-full md:w-10/12">
            <h2 className="md:w-3/4 xl:w-8/12 font-display font-bold text-3xl lg:text-4xl text-accent leading-tight">
              {subtitle}
            </h2>
          </div>
        </div>

        <div class="columns">
          <div class="w-full md:w-10/12">
            <PageContent
              className="prose lg:prose-xl max-w-none"
              content={content}
            />
          </div>
        </div>
      </section>
      <section className="container mt-4 md:mt-16 lg:mt-24">
        <div className="columns items-center flex-col md:flex-row">
          <div className="w-full md:w-5/12 lg:w-4/12 flex md:flex-col items-end md:items-end space-x-6 md:space-y-6">
            <div className="w-2/3 md:w-full rounded-lg lg:rounded-xl p-4 md:p-6 lg:p-8 bg-accent-p2 space-y-4 lg:space-y-6 text-center sm:text-left">
              <img
                src={sprout}
                alt=""
                className="hidden md:block md:w-24 md:-mt-24 lg:w-32 lg:-mt-32 md:pb-2"
              />

              <h3 className="font-heading text-2xl md:text-3xl xl:text-4xl">
                Grow with Us
              </h3>
              <p className="text-md md:text-lg lg:text-xl">
                Want to work for one of VentureOhio’s Top 50 Startups&nbsp;to
                Watch?
              </p>

              <AnchorLink
                to="https://recruiting.paylocity.com/recruiting/jobs/All/32ce305a-6464-4d04-8a69-f5e0c909e625/ScriptDrop-Inc"
                className="btn btn-accent btn-avenir-semibold w-full sm:w-auto md:w-full text-sm md:text-lg"
              >
                Apply Now
              </AnchorLink>
            </div>

            <div className="w-1/3 md:w-full items-end md:items-start md:justify-end md:flex md:space-x-6 space-y-6 md:space-y-0">
              <img
                className="hidden md:block md:w-1/4 lg:w-1/3"
                src={gptw}
                alt="Great Place to Work 2019-2020 Badge"
              />

              <figure className="w-full md:w-1/2 rounded-lg lg:rounded-xl overflow-hidden">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: photoTertiary,
                    alt: "The ScriptDrop team",
                  }}
                />
              </figure>
            </div>
          </div>

          <div className="w-full md:w-7/12 xl:w-8/12 space-y-6">
            <div className="flex md:flex-none items-start space-x-6 md:space-x-0">
              <img
                className="w-2/12 md:hidden"
                src={gptw}
                alt="Great Place to Work 2019-2020 Badge"
              />

              <figure className="w-3/4 md:w-full rounded-lg lg:rounded-xl overflow-hidden">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: photoPrimary,
                    alt: "The ScriptDrop team",
                  }}
                />
              </figure>
            </div>

            <div className="flex items-end md:items-start space-x-6">
              <figure className="w-3/4 rounded-lg lg:rounded-xl overflow-hidden">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: photoSecondary,
                    alt: "The ScriptDrop team",
                  }}
                />
              </figure>

              <img
                className="w-2/12"
                src={twp}
                alt="Top Work Places 2020 Badge"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-accent-p2 mt-4 md:mt-16 lg:mt-24 py-3 md:py-8 lg:py-12 xl:py-16">
        <div className="container space-y-4 lg:space-y-8">
          <h2 className="font-heading text-2xl md:text-3xl lg:text-4xl text-accent-m2">
            Work with Us
          </h2>

          <ul className="columns">
            <Feature
              className="w-full md:w-4/12"
              headingClassName="text-accent-m1"
              heading="Flexible office model"
              icon={faHome}
            >
              ScriptDrop embraces a hybrid-working model to accommodate the
              needs of our employees. We welcome you to our office in downtown
              Grandview, Ohio or you may work remotely from your own home.
            </Feature>
            <Feature
              className="w-full md:w-4/12"
              headingClassName="text-accent-m1"
              heading="Benefits"
              icon={faGift}
            >
              <div class="space-y-4">
                <p>
                  Life, medical, dental, and vision insurance, with 99% of
                  premiums covered by ScriptDrop
                </p>
                <p>401k match</p>
                <p>Paid maternity/paternity leave</p>
              </div>
            </Feature>
            <Feature
              className="w-full md:w-4/12"
              headingClassName="text-accent-m1"
              heading="Generous PTO"
              icon={faSuitcase}
            >
              In addition to PTO, one mandatory day off per month for mental
              health
            </Feature>
          </ul>
        </div>
      </section>

      <section className="py-6 md:py-8 lg:py-12 xl:py-16">
        <div className="container space-y-4 space-y-8 lg:space-y-10">
          <div class="text-center">
            <h3 className="font-heading text-3xl lg:text-4xl text-accent-m2">
              Executive Leadership
            </h3>
          </div>
          <div className="columns">
            <div className="w-auto grid gap-3 sm:gap-6 grid-cols-3">
              <Headshot
                name="Amanda Epp"
                title="CEO"
                image={amanda}
                className="bg-royal"
              />
              <Headshot
                name="Dustin Crosby"
                title="CFO"
                image={dustin}
                className="bg-goldenrod"
              />
              <Headshot
                name="Katie Schrader"
                title="Chief of Staff"
                image={katie}
                className="bg-violet"
              />
            </div>
          </div>

          <div className="text-center">
            <Link
              to="/aboutus/leadership"
              className="btn px-4 md:px-8 btn-royal-o btn-avenir-semibold"
            >
              More About Our Leadership Team&nbsp;→
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

AboutPageTemplate.propTypes = {
  subtitle: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout
      headerClassName="bg-accent curve-br"
      headerPhoto={
        <Img
          imgStyle={{
            objectFit: "contain",
            objectPosition: "100% 100%",
          }}
          fluid={post.frontmatter.headerPhoto.childImageSharp.fluid}
        />
      }
      navbarLogo={logo}
      className="accent-royal"
      headerTitle={post.frontmatter.heading}
    >
      <Helmet titleTemplate="%s">
        <title>{`${post.frontmatter.title}`}</title>
        <meta property="og:title" content={post.frontmatter.title} />
      </Helmet>

      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        content={post.html}
        photoPrimary={post.frontmatter.teamPhotoPrimary}
        photoSecondary={post.frontmatter.teamPhotoSecondary}
        photoTertiary={post.frontmatter.teamPhotoTertiary}
        amanda={data.amanda}
        dustin={data.dustin}
        katie={data.katie}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const teamPageQuery = graphql`
  query TeamPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        subtitle
        headerPhoto {
          childImageSharp {
            fluid(
              maxWidth: 256
              quality: 80
              traceSVG: { background: "transparent", color: "#236BA0" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        teamPhotoPrimary {
          childImageSharp {
            fluid(
              maxWidth: 800
              quality: 85
              traceSVG: { background: "transparent", color: "#D4E6FE" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        teamPhotoSecondary {
          childImageSharp {
            fluid(
              maxWidth: 800
              quality: 85
              traceSVG: { background: "transparent", color: "#D4E6FE" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        teamPhotoTertiary {
          childImageSharp {
            fluid(
              maxWidth: 800
              quality: 85
              traceSVG: { background: "transparent", color: "#D4E6FE" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }

    amanda: file(relativePath: { eq: "media/headshot-amanda.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 256
          quality: 80
          traceSVG: { background: "transparent", color: "#003052" }
        ) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    katie: file(relativePath: { eq: "media/katieschrader_cutout.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 256
          quality: 80
          traceSVG: { background: "transparent", color: "#003052" }
        ) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    dustin: file(relativePath: { eq: "media/image_dustin_crosby_2.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 256
          quality: 80
          traceSVG: { background: "transparent", color: "#003052" }
        ) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
