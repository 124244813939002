import React from "react";

import PreviewCompatibleImage from "./PreviewCompatibleImage";

export default ({ image, className, name, title }) => (
  <figure className="flex items-center justify-center">
    <div
      className={`${className} flex-shrink-0 w-24 h-24 md:w-32 md:h-32 lg:w-48 lg:h-48 rounded-full overflow-hidden relative`}
    >
      <PreviewCompatibleImage
        imageInfo={{
          image: image,
          alt: name,
          imageStyle: {
            position: "absolute",
            width: "95%",
            bottom: "0",
            left: "2%",
          },
        }}
      />
    </div>
  </figure>
);
